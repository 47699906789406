<template>
  <section class="component-deduction-item">
    <bg-divider class="mt-8 mb-24" />

    <div class="deduction-container">
      <div class="flex justify-space-between align-center mb-16">
        <bg-text>Pengurangan {{ index + 1 }}</bg-text>
        <bg-button
          variant="secondary"
          class="icon-button"
          :disabled="disableDelete"
					@click="removeDeduction"
        >
          <bg-icon name="delete" class="icon-spacing" size="sm" />
        </bg-button>
      </div>

      <bg-grid>
        <bg-grid-item :col="6">
          <bg-field label="Nama Biaya" class="mb-16">
            <bg-select
              :value="deduction.feeType"
              :options="options.feeType"
              @change="selectFeeType($event)"
              data-testid="select-deduction-fee-type"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="12">
          <fee-type-undertable-commission
            v-if="deduction.feeType === 'undertable-commission'"
            :options="options.undertableCommission"
						:deduction-value="deduction.value"
            @on-type-in="onTypeIn($event)"
          />
        </bg-grid-item>
      </bg-grid>
    </div>
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgButton,
  BgText,
  BgIcon,
  BgField,
  BgSelect,
  BgDivider,
} from 'bangul-vue';

export default {
  name: 'DeductionItem',

  components: {
    BgGrid,
    BgGridItem,
    BgButton,
    BgText,
    BgIcon,
    BgField,
    BgSelect,
    BgDivider,
    FeeTypeUndertableCommission: () => import('../FeeTypeUndertableCommission'),
  },

  props: {
    index: {
      type: Number,
      required: true,
    },
    deduction: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    disableDelete: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      feeType: '',
    };
  },

  methods: {
    selectFeeType(value) {
      this.$emit('select-fee-type', value);
    },
    onTypeIn(value) {
      this.$emit('on-type-in', value);
    },
		removeDeduction() {
			this.$emit('remove-deduction');
		}
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  padding: 8px;
  line-height: 1;
  .bg-c-icon {
    vertical-align: top;
  }
}
</style>
